<template>
  <div>
    <b-row class="custom-divider" align-h="center">
      <b-col lg="5" cols="10">
        <el-input
          v-model="keywords"
          class="shadow mt-5 rounded-lg"
          size="large"
          :placeholder="$t('input')"
          prefix-icon="el-icon-search"
          clearable
          @change="search"
        />
      </b-col>
    </b-row>
    <div class="px-3 mt-5 text-center pt-5">
      <h3>{{ $t('list.title') }}</h3>
      <span>{{ $t('list.intro') }}</span>
    </div>
    <b-row class="mt-3 px-3" align-h="start">
      <b-col xl="2" lg="3" class="my-3 px-5 pl-lg-5 pr-lg-3">
        <el-select v-model="otherLang" filterable :placeholder="$t('info.otherLang')" @change="reset(); getList()">
          <el-option v-for="item in langOptions" :key="item.value" :label="item.value" :value="item.value" />
        </el-select>
      </b-col>
      <b-col xl="2" lg="3" class="my-3 px-5 pr-lg-5 pl-lg-3">
        <el-select v-model="category" filterable :placeholder="$t('info.category')" @change="reset(); getList()">
          <el-option v-for="item in categoryOptions" :key="item.value" :label="item.value" :value="item.value" />
        </el-select>
      </b-col>
    </b-row>
    <b-row class="mt-3 px-3">
      <b-col v-for="item in list" :key="item.id" xl="3" lg="4" md="6" sm="12" cols="12" class="my-3 px-3 pb-3">
        <div>
          <b-img class="profile rounded-lg" fluid :src="item.profile" @click="goDetails(item.id)" />
        </div>
        <div class="mt-3 shadow-sm rounded-bottom px-3 pb-3">
          <b-row class="mt-3" align-v="center" align-h="between">
            <b-col cols="8" class="font-weight-bold">
              <font-awesome-icon icon="user" class="icon-name mr-2" />{{ item.name }}
            </b-col>
            <b-col class="text-secondary" style="font-size: 0.9rem">
              {{ item.userId }}
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" class="text-secondary mb-1" style="font-size: 0.8rem">
              <font-awesome-icon class="mr-3" icon="map-marker-alt" />
              <span v-if="$i18n.locale === 'zh'" class="mt-2">{{ item['nationality'].split('-')[1] }}</span>
              <span v-if="$i18n.locale === 'en'" class="mt-2">{{ item['nationality'].split('-')[0] }}</span>
            </b-col>
            <b-col cols="12">
              <el-tag v-if="item.category" class="mt-2" type="warning">{{ item.category.replace(/,/g, ' ') }}</el-tag>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-container v-observe-visibility="{ callback: loadingData }" class="text-center">
        <font-awesome-icon v-if="hasData" icon="spinner" spin />
        <span v-else class="text-secondary" style="font-size: 0.8rem">{{ $t('infinite.noMore') }}</span>
      </b-container>
    </b-row>
  </div>
</template>

<script>
import { fetchList, fetchProp } from '@/api/hosts'

export default {
  name: 'HostsList',
  data() {
    return {
      count: 12,
      page: 1,
      limit: 15,
      otherLang: '',
      category: '',
      langOptions: [],
      categoryOptions: [],
      list: [],
      keywords: '',
      busy: false,
      hasData: true
    }
  },
  watch: {
    $route() {
      location.reload()
    }
  },
  created() {
    this.keywords = this.$route.query.keywords
    this.getProp()
  },
  methods: {
    getProp() {
      fetchProp().then(e => {
        this.langOptions = e.data.lang
        this.categoryOptions = e.data.categories
        this.langOptions.unshift({ value: '' })
        this.categoryOptions.unshift({ value: '' })
      })
    },
    reset() {
      this.page = 1
      this.list = []
      this.hasData = true
    },
    goDetails(id) {
      const redirect = this.$router.resolve({
        path: '/live/details',
        query: { id: id }
      })
      window.open(redirect.href, '_blank')
    },
    search(keywords) {
      this.$router.push({
        path: '/live',
        query: { keywords }
      })
      location.reload()
    },
    getList() {
      this.busy = true
      const params = { page: this.page, limit: this.limit }

      if (this.otherLang) {
        params['otherLang'] = this.otherLang
      }
      if (this.category) {
        params['category'] = this.category
      }
      if (this.keywords) {
        params['keywords'] = this.keywords
      }

      fetchList(params).then(e => {
        if (e.data) {
          this.list.push(...e.data)
          this.page++
        } else {
          this.hasData = false
        }
        this.busy = false
      }).catch(err => {
        console.log(err)
      })
    },
    loadingData() {
      if (this.hasData && !this.busy) {
        this.getList()
      }
    }
  }
}
</script>

<style scoped>
.custom-divider {
  background-color: #f8f9fa;
  height: 75px;
}
.custom-divider>>>.el-input__inner {
  border: 0;
  height: 60px;
}
.icon-name {
  color: darkorange;
}
.profile {
  cursor: pointer;
}
.tag-info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}
</style>
